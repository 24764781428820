





































































import { Component, PropSync, Vue } from "vue-property-decorator";
import store from "@/store";
//  types
import { ExtendedVessel } from "@/types/vessel";
import { LongTrendFilter } from "@/types/LongTrendFilter";
//  modules
import { getModule } from "vuex-module-decorators";
import VesselsModule from "@/store/clients/Vessels.module";
import SnackbarModule from "@/store/clients/Snackbar.module";
import { apiGet, apiPut } from "@/services/api";

const Vessels = getModule(VesselsModule, store);
const Snackbar = getModule(SnackbarModule, store);

@Component
export default class LongTrendFilterModal extends Vue {
  @PropSync("isOpen", { type: Boolean, default: false }) dialog!: boolean;

  filter: LongTrendFilter = {} as LongTrendFilter;
  windBeaufort = [
    { text: "BF3", value: 3 },
    { text: "BF4", value: 4 },
    { text: "BF5", value: 5 },
    { text: "BF6", value: 6 },
    { text: "BF7", value: 7 },
    { text: "BF8", value: 8 },
    { text: "BF9", value: 9 },
    { text: "BF10", value: 10 },
    { text: "BF11", value: 11 },
    { text: "BF12", value: 12 },
  ];

  // @Getters
  get vessel(): ExtendedVessel | null {
    if (!Vessels.currentVessel) return null;
    return Vessels.currentVessel;
  }

  get isSaveDisabled(): boolean {
    return !this.filter.minPercentMcr || !this.filter.maxBeaufortWind;
  }

  fieldRequired = {
    required: (value: any) => !!value || "Field is required",
    mcr: (value: any) => {
      if (!!!value) return "Field is required";
      else return (1 <= value && value <= 100) || "MCR must be a number between 1 and 100.";
    },
  };

  numericFilter(event: any): void {
    event = event ? event : window.event;
    const expect = event.target.value.toString() + event.key.toString();
    return !/^\d+$/.test(expect) ? event.preventDefault() : false;
  }

  async fetchFilter(): Promise<void> {
    if (!this.vessel) return;
    this.filter = await apiGet<LongTrendFilter>(`/LongTrend/Filter?vesselId=${this.vessel.id}`);
  }

  open(): void {
    this.fetchFilter();
    this.dialog = true;
  }

  cancel(): void {
    this.dialog = false;
  }

  async updateFilter(): Promise<void> {
    try {
      await apiPut<LongTrendFilter, void>("/LongTrend/Filter", this.filter);
      this.$emit("filterUpdated");
      Snackbar.showSnackbar({ text: "Filter settings successfully updated", color: "success" });
    } catch (error) {
      Snackbar.showSnackbar({ text: "Failed to update filter settings" });
    } finally {
      this.dialog = false;
    }
  }

  async created(): Promise<void> {
    if (!this.vessel) return;
    await this.fetchFilter();
  }
}
