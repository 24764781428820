





























































































































































































































































import { Component, Ref, Vue } from "vue-property-decorator";
import store from "@/store";
import moment from "moment";
import LongTrendExportClient from "@/Scripts/clients/long-trend-export-client";
//modules
import { getModule } from "vuex-module-decorators";
import LongTrendsModule from "@/store/clients/LongTrends.module";
import VesselsModule from "@/store/clients/Vessels.module";
import VesselEventsModule from "@/store/clients/VesselEvents.module";
//components
import FeatureNotAvailable from "@/components/FeatureNotAvailable.vue";
import ConfirmDialog from "@/components/ConfirmDialog.vue";
import EditVesselEvent from "@/components/EditVesselEvent.vue";
import DiagnosticsDetails from "@/components/diagnosticsDetails/DiagnosticsDetails.vue";
import DiagnosticCard from "@/components/DiagnosticCard.vue";
import LongTrendFilterModal from "@/components/diagnostics/LongTrendFilterModal.vue";
//types
import { VesselEvent } from "@/types/vesselEvent";
import { ExtendedVessel } from "@/types/vessel";
import { IVesselLongTrend, IVesselLongTrends } from "@/types/vesselLongTrends";

const Vessels = getModule(VesselsModule, store);
const VesselEvents = getModule(VesselEventsModule, store);
const LongTrends = getModule(LongTrendsModule, store);

export interface IVesselLongTrendsExtended extends IVesselLongTrend {
  visible: boolean;
}

@Component({
  components: {
    FeatureNotAvailable,
    ConfirmDialog,
    EditVesselEvent,
    DiagnosticsDetails,
    DiagnosticCard,
    LongTrendFilterModal,
  },
})
export default class DiagnosticsKyma extends Vue {
  @Ref("confirmDelete") confirmDelete!: any;
  @Ref("editVessel") editVessel!: any;
  @Ref("filterModal") filterModal!: any;
  @Ref("diagnosticsDetails") diagnosticsDetails!: any;
  EditEventOpen = false;
  FilterModalOpen = false;
  detailsError = false;
  notification = {
    message: "",
    duration: 2000,
    visible: false,
  };

  isLoading = true;
  showError = false;
  showSpeedLogDeviation = true;

  get vessel(): ExtendedVessel | null {
    return Vessels.currentVessel;
  }

  get featureEnabled(): boolean {
    if (!this.vessel) return false;
    return this.vessel.features.some(feature => feature.name === "Diagnostics");
  }

  get isCloudLongTrendFeatureEnabled(): boolean {
    if (!this.vessel) return false;
    return this.vessel.features.some(feature => feature.name === "CloudLongTrend");
  }

  get vesselLongTrends(): IVesselLongTrendsExtended[] {
    const vesselLongTrends: IVesselLongTrendsExtended[] = [];
    const trend: IVesselLongTrends = { ...LongTrends.vesselLongTrends };
    const shipSpeedDeviationKey = "ShipSpeedDev";
    Object.keys(trend).forEach(key => {
      if (key === shipSpeedDeviationKey) {
        vesselLongTrends.push({ ...trend[key], visible: this.showSpeedLogDeviation });
      } else {
        vesselLongTrends.push({ ...trend[key], visible: true });
      }
    });

    return vesselLongTrends;
  }

  get events(): VesselEvent[] {
    return VesselEvents.allEvents;
  }

  eventTableHeaders = [
    { text: "Date", align: "start", sortable: false, value: "date" },
    { text: "Name", align: "start", sortable: false, value: "name" },
    { text: "Type", align: "start", sortable: false, value: "type" },
    { text: "Origin", align: "start", sortable: false, value: "origin" },
    { text: "Actions", align: "start", sortable: false, value: "actions" },
  ];

  get eventTableRows(): any[] {
    const tableRows: any[] = [];

    this.events.forEach(event => {
      tableRows.push({
        event,
        date: event.timestamp,
        name: event.name,
        type: event.type,
        origin: event.source,
        actions: ["edit", "delete"],
      });
    });

    return tableRows;
  }

  async created(): Promise<void> {
    if (!this.vessel) {
      this.showError = true;
    } else {
      try {
        await Promise.all([VesselEvents.fetchEvents(this.vessel.id), VesselEvents.updateTrendEventTypes(), LongTrends.fetchVesselLongTrends(this.vessel.id)]);
      } catch (error) {
        console.warn(error);
        this.showError = true;
      } finally {
        this.isLoading = false;
      }
    }
  }

  async createEvent(): Promise<void> {
    const success = await this.editVessel.open();
    if (success) {
      this.notification.message = "Event created";
      this.notification.visible = true;
      this.vessel ? LongTrends.fetchVesselLongTrends(this.vessel.id) : null;
    }
  }

  async editEvent(vesselEvent: VesselEvent): Promise<void> {
    const success = await this.editVessel.open(vesselEvent);
    if (success) {
      this.notification.message = "Event edited";
      this.notification.visible = true;
      this.vessel ? LongTrends.fetchVesselLongTrends(this.vessel.id) : null;
    }
  }

  async deleteEvent(vesselEvent: VesselEvent): Promise<void> {
    const confirmed = await this.confirmDelete.open(`Delete ${vesselEvent.name}`, "Are you sure you want to delete this event?");
    if (confirmed) {
      await VesselEvents.deleteEvent(vesselEvent);
      this.notification.message = "Event deleted";
      this.notification.visible = true;
      this.vessel ? LongTrends.fetchVesselLongTrends(this.vessel.id) : null;
    }
  }

  async onFilterUpdated(): Promise<void> {
    if (!this.vessel) {
      this.showError = true;
    } else {
      this.isLoading = true;
      try {
        await Promise.all([VesselEvents.fetchEvents(this.vessel.id), VesselEvents.updateTrendEventTypes(), LongTrends.fetchVesselLongTrends(this.vessel.id)]);
      } catch (error) {
        console.warn(error);
        this.showError = true;
      } finally {
        this.isLoading = false;
      }
    }
  }

  openFilterModal(): void {
    this.filterModal.open();
  }

  showDiagnosticsDetails(data: any): void {
    if (!data.vesselId) return;
    this.diagnosticsDetails.open(data);
  }

  exportExcel(): void {
    if (this.vessel) {
      LongTrendExportClient.exportExcel(this.vessel.id);
    }
  }

  formatEventDate(date: string): string {
    return moment.utc(date).format("DD.MMM YYYY");
  }
}
