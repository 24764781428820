import store from "@/store";

export interface FetchOptions extends RequestInit {
  headers?: Record<string, string>;
}

async function apiFetch<T>(endpoint: string, options: FetchOptions = {}): Promise<T> {
  const accessToken = store.getters["Auth/getAccessToken"];
  const headers: Record<string, string> = {
    "Content-Type": "application/json",
    Authorization: accessToken ? `Bearer ${accessToken}` : "",
    ...options.headers,
  };

  const response = await fetch(`${process.env.VUE_APP_API_BASE_URL}${endpoint}`, {
    ...options,
    headers,
  });

  if (!response.ok) {
    throw new Error(`HTTP request failed. Status: ${response.status}`);
  }

  const contentType = response.headers.get("content-type");
  return contentType && contentType.includes("application/json")
    ? response.json()
    : response.text();
}

export async function apiGet<T>(endpoint: string, options: FetchOptions = {}): Promise<T> {
  return apiFetch(endpoint, { ...options, method: "GET" });
}

export async function apiPost<T, U>(endpoint: string, data: T, options: FetchOptions = {}): Promise<U> {
  return apiFetch(endpoint, {
    ...options,
    method: "POST",
    body: JSON.stringify(data),
  });
}

export async function apiPut<T, U>(endpoint: string, data: T, options: FetchOptions = {}): Promise<U> {
  return apiFetch(endpoint, {
    ...options,
    method: "PUT",
    body: JSON.stringify(data),
  });
}
